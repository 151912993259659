@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "css/utility-patterns.css";
@import "css/range-slider.css";
@import "css/toggle-switch.css";
@import "css/flatpickr.css";
@import "css/theme.css";

*,
body,
.font-inter {
  font-family: "Inter", sans-serif !important;
}

body {
  @apply bg-gray-100;
}

.button-box-shadow {
  box-shadow: 0px 1px 2px rgba(15, 23, 42, 0.08);
}

.input-box-shadow {
  box-shadow: 0px 1px 2px rgba(15, 23, 42, 0.04);
}
